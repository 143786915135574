.container {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  text-align: left;
}

.center_form {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

.title {
  position: relative;
}

.label {
  font-size: 24px;
  font-weight: 400;
}

.label-grouped {
  font-size: 21px;
  font-weight: 400;
}

.count {
  position: absolute;
  left: -32px;
  top: 10px;
  font-size: 12px;
  z-index: 1;
}/*# sourceMappingURL=audioResponse.css.map */