.qrate-logo-old {
  width: 9rem;
  padding-top: 1rem;
}

.section {
  background: #fff;
  overflow: hidden auto;
  height: 100vh;
  width: 100%;
  padding: 0;
  text-align: center;
}

.section::-webkit-scrollbar {
  display: none;
}

.progress_bar {
  width: 90vw;
  max-width: 600px;
  margin: 20px auto 25px auto;
  opacity: 1;
  z-index: 10;
  text-align: center;
}

.progress_bar .ant-progress-text {
  display: none !important;
}

.form-details {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.form-name {
  font-size: 17px;
  font-weight: 400;
}

.form-employer-logo {
  width: 2.5rem;
}/*# sourceMappingURL=formField.css.map */