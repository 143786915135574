.canvas {
  display: flex;
  width: 100%;
  height: 80px;
  margin: 20px auto;
  padding: 0 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
}

@media screen and (min-width: 575px) {
  .canvas {
    margin: 20px 0;
    width: 460px;
  }
}
.timer {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.audio {
  margin: 20px 0;
}

#recording-btn {
  background-color: #52c41a;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
  margin-right: 7px;
}

#stop-recording-btn {
  background-color: #ef4747;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
  margin-right: 7px;
}

#troubleshoot_btn_audio {
  border-radius: 5px;
  color: #ef4747;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
}

.mic_icon {
  padding-left: 1rem;
  font-size: large;
}/*# sourceMappingURL=audioRecorder.css.map */