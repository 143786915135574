.container {
    margin: auto;
    width: min(100%, 800px);
    min-height: 100vh;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    justify-content: space-between;
}
.qrate-logo {
    width: 100%;
    text-align: center;
}
.qrate-logo img {
    max-height: 40px;
}
.container .question_label {
    font-size: 2.2rem;
}
.container .question_sub_text {
    font-size: 1.2rem;
    text-align: justify;
    margin-top: 10px;
    border: 2px dashed grey;
    padding: 10px 20px;
    max-height: 40vh;
    overflow-y: scroll;
}
.audio-recorder {
    /* text-align: center; */
    padding: 20px;
    margin: 15px;
    border: 2px solid #bbb;
}
.button-collection {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    gap: 10px;
    margin: 20px;
    margin-bottom: 0;
    flex-wrap: wrap;
}
.button-collection button {
    border-radius: 5px;
    font-weight: bold;
}
.canvas {
    width: 100%;
}
.permission-video {
    width: 400;
}
.recorder-text-container {
    text-align: center;
    font-size: 1.2rem;
    margin: 10px;
    font-weight: bold;
}
.recorder-text {
    margin-right: 10px;
    background-color: red;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    display: inline-block;
    border: 2px solid #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(255, 0, 0) 0px 0px 0px 1px;
    /* rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px */
}
.recorder-header-text {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 3px;
}

@media (max-width: 650px) {
    .container {
        padding-inline: 5px;
    }
    .container .question_label {
        font-size: 1.8rem;
    }
    .container .question_sub_text {
        font-size: 1rem;
        padding-inline: 10px;
        text-align: left;
    }
    .welcome-modal {
        top: 10px;
    }
    .permission-video {
        width: fit-content;
        max-width: 100%;
    }
}

/* Welcome modal */
.welcome-modal-heading {
    color: #1890ff;
    display: block;
    text-align: center;
    font-size: 1.2rem;
}
.welcome-modal-footer {
    display: flex;
    justify-content: center;
}
.welcome-modal-footer button {
    min-width: 60%;
    font-size: 1.1rem;
    height: auto;
}
