@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");


* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background-color: #fafafa;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: none;
  cursor: pointer;
}

hr {
  margin: 0px;
}

.arrows-abs {
  position: absolute;
  padding-left: 9px;
  border: none !important;
  z-index: 9999;
}
