@import url(https://fonts.googleapis.com/css?family=Open+Sans:800);

.countdown-container {
    display: flex;
    justify-content: center;
}
.countdown-outer {
    padding: 20px;
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
}
.countdown-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 85px;
    color: rgb(34, 213, 201);
    font: 90px "Open Sans";
    height: 100px;
    width: 100px;
    background-color: #eee;
    border-radius: 50%;
}

.urgent {
    color: rgb(255, 74, 74);
    animation: breath 1s alternate infinite ease-in-out;
}

.success {
    color: #00DFA2;
}

@keyframes breath {
    0%   {transform: scale(.8);}
    50% {transform: scale(1);}
    100% {transform: scale(.8);}
}


